@import 'uikit/styles/fonts';
@import 'uikit/styles/responsivePoints';
$problemLineHeight: 28px;
$problemPadding: 10px;
$paddingTop: 70px;
$indigo: #554dcf;

.ProblemLinksContainer {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 64px;
  padding-right: 20px;
}

.Navigation {
  /* combined with appearance..yml */
  padding-top: $paddingTop;
  width: 64px;
  color: #ffffff;
  font-family: $familyTTNorms;
  flex-shrink: 0;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 1;

  &Before {
    /* combined with appearance..yml */
    content: '';
    width: 100%;
    height: 64px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 64px;
    pointer-events: none;
  }

  &After {
    /* combined with appearance..yml */
    content: '';
    width: 100%;
    height: 64px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
}

.ProblemLinks {
  position: absolute;
  left: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: $problemLineHeight;
  font-weight: 900;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.Problem {
  font-size: 18px;
  color: #ffffff;
  width: 28px;
  height: 28px;
  font-family: $familyLato;
  text-align: center;
  padding: 10px 0;

  &:hover {
    color: #f31e55;
  }
}

.Disabled {
  color: #5848d7;
}

.Incorrect {
  background: linear-gradient(
  to bottom right,
  transparent calc(50% - 2px),
  $indigo calc(50% - 1px),
  $indigo calc(50% + 1px),
  transparent calc(50% + 2px)
  ) no-repeat 0 10px / 28px 28px;
}

.CurrentProblemPointer {
  position: absolute;
  left: 56px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 8px solid white;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.TheoryIcon {
  fill: currentColor;
  height: 16px;
}

@media (max-width: $smallWindowWidth) {
  .CurrentProblemPointer {
    left: 40px;
  }
  .ProblemLinksContainer {
    width: 48px;
  }
  .Navigation {
    width: 48px;
  }
  .ProblemLinks {
    left: 10px;
  }
}
