[hidden] {
  display: none !important;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  font-weight: 500;
  border-radius: 48px;
  cursor: pointer;
  transition: background-color 0.1s ease-out,
  border-color 0.2s ease-out,
  transform 0.1s ease-out;
  font-family: 'TT Norms', sans-serif;
}
.button:hover {
  transform: scale(1.1);
}
.button:active {
  transform: scale(0.99);
  transition: none;
}

.button:disabled,
.button:disabled:active,
.button:disabled:focus,
.button:disabled:hover {
  cursor: initial;
  transform: none;
  transition: none;
  box-shadow: none;
}

.button.theme-yellow {
  color: #333333;
  background-color: #ffe24a;
  box-shadow: 0 5px 32px rgba(255,205,8,0.35);
}

.button.theme-yellow:hover {
  background-color: #ffd353;
}
.button.theme-yellow:active {
  background-color: #ffe24a;
}

.button.size-medium {
  height: 64px;
  font-size: 24px;
  padding: 0 22px;
}

a.button {
  text-decoration: none;
}
