.Footer {
  padding: 50px;
  flex: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.ThemeDark {
  /* defined in appearance.yml */
}

.ThemeLight {
  /* defined in appearance.yml */
}

.ColumnTitle {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 500;
}

.Links {
  display: flex;
  flex-direction: column;
}

.Line {
  line-height: 1.8;
}

@media (min-width: 767px) {
  .Footer {
    flex-direction: row;
    padding: 50px 105px;
  }
}
