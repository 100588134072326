@import './fontsSources/Fact_Regular/Fact_Regular.scss';
@import './fontsSources/Fact_Italic/Fact_Italic.scss';
@import './fontsSources/FactSemiExpanded/Fact_Semi Expanded Medium.scss';
@import './fontsSources/FactSemiExpandedBlack/Fact_Semi Expanded Black.scss';
@import './fontsSources/FactSemiExpandedExtraBold/Fact_Semi Expanded Extra Bold.scss';
$familyFact: 'FactWebRegular';
$familyFactItalic: 'FactWebItalic';
$familyFactSemiExpanded: 'FactWebSemiExpanded';
$familyFactSemiExpandedBlack: 'FactWebSemiExpandedBlack';
$familyFactSemiExpandedExtraBold: 'FactWebSemiExpandedExtraBold';
$familyHelvetica: 'Helvetica';
$familyHelveticaNeue: 'HelveticaNeue';
