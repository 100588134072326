@import 'uikit/styles/fonts.scss';
$modalHeight: 390px;

.User {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
  font-family: $familyTTNorms;
  font-size: 22px;
  font-weight: bold;
  background: #ffffff;
}

.Avatar {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .User {
    height: 32px;
    width: 32px;
    font-size: 18px;
    font-weight: 500;
    line-height: 33px;
  }
}

.ThemeDark {
  /* defined in appearance.yml */
} /* remove */

.ThemeLight {
  /* defined in appearance.yml */
} /* remove */

@keyframes fadeIn-bg {
  from {
    background-color: transparentize(#ffffff, 1);
  }
  to {
    background-color: transparentize(#ffffff, 0.45);
  }
}

@keyframes fadeOut-bg {
  from {
    background-color: transparentize(#ffffff, 0.45);
  }
  to {
    background-color: transparentize(#ffffff, 1);
  }
}

.UserProfileModalContent {
  margin: unset;
  background-color: #ffffff;
  outline: none;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  min-height: 300px;
  position: relative;
  top: $modalHeight / 2 !important;
  display: table;
  box-sizing: border-box;
  width: 100%;
  height: $modalHeight;
  padding: 60px 80px 60px;
  font-family: $familyTTNorms;
  border-radius: 0;
  max-width: unset;
}

.UserProfileModalInnerWrapper {
  position: unset;
  display: block;
}

.UserProfileModalOverlay {
  background-color: transparentize(#ffffff, 0.45);
}

.UserProfileModalOverlayAfterOpen {
  animation: fadeIn-bg 0.2s ease-out both;
}

.UserProfileModalOverlayBeforeClose {
  animation: fadeOut-bg 0.2s ease-out both;
}
