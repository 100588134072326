@import 'uikit/styles/fonts';

.QRReader {
  display: flex;
  background-color: rgba(0, 0, 0, 0.13);
  justify-content: center;
  align-items: center;
  height: 64px;
  margin-top: -10px;
  padding-top: -10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.Preview {
  height: 360px;
  position: relative;
}

.Footer {
  height: 80px;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;

  svg {
    padding-right: 32px;
  }

  span {
    font-family: $familyTTNorms;
    font-size: 14px;
    font-weight: 500;
  }
}


@media (min-width: 768px) {
  .QRReader {
    width: 64px;
    height: 100%;
    margin-right: -10px;
    padding-right: 10px;
    padding-top: 0;
    margin-top: 0;
  }

  .Preview {
    width: 360px;
  }
}
