@import 'uikit/styles/fonts';
$circleDiameter: 90vmin;
$minSize: 500px;

.ReactModalBodyOpen,
.ReactModalHtmlOpen {
  overflow: hidden;
}

.Modal {
  max-width: none;
  box-sizing: border-box;
  width: $circleDiameter;
  height: $circleDiameter;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  top: 5vh;
  left: 50vw;
  min-height: $minSize;
  min-width: $minSize;
  z-index: 100;
  margin: 0;
  padding: 0;
  border: none;
  transform: translate(-50%, 0);
}

.PreHeader {
  position: fixed;
  top: 0;
  right: 0;
}

.EnterNameModalInnerWrapper {
  position: relative;
  height: 100%;
  padding: 0 17%;
  min-height: $minSize;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
}

.EnterNameModalOverlay {
  background-color: rgba($color: #3223ff, $alpha: 0.86);
  overflow-x: hidden;
}

.CloseButton {
  z-index: 1001;
  color: #ffffff;
}

.EnterNameCloseButton {
  position: fixed;
  color: #ffffff;
}

.CircularModalContent {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  min-height: $minSize;
  box-sizing: border-box;
}

.CircularContent {
  position: relative;
  height: 100%;
  width: 100%;
  background: transparent;
  padding: 0;
  margin: 0;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 80vw;
  flex: 0 0 auto;
  height: 28vmin;
  min-height: 150px;
  padding-top: 5vmin;
}

.Content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 0 80px;
  font-family: $familyTTNorms;
  flex: 0 0 48vmin;
  height: 40vmin;
  min-height: 300px;
}

.Footer {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 16%;
  font-family: $familyTTNorms;
}

.DismissLink {
  font-size: 18px;
  font-weight: 500;
}

.Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FieldSet {
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
}

.Field {
  margin-bottom: 17px;
}
