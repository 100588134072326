$matterColor: #ffffff;
$antiMatterColor: #000000;
$darkMatterColor: #262636;
$energyColor: #e12631;
$energyHoverColor: #8626e7;
$doubleEnergyColor: #7ce0ff;
$doubleEnergyHoverColor: #84ffd3;
$darkEnergyColor: #9efceb;
$darkEnergyHoverColor: #76ff9c;
$wizdomTanColor: #f8f7f1;
$panicColor: #ec4953;
$skillsGoodColor: #78d78a;
$skillsBadColor: #fe7f77;
$dimDarknessColor: #4e4e5e;

:export {
  matterColor: $matterColor;
  antiMatterColor: $antiMatterColor;
  darkMatterColor: $darkMatterColor;
  energyColor: $energyColor;
  energyHoverColor: $energyHoverColor;
  doubleEnergyColor: $doubleEnergyColor;
  doubleEnergyHoverColor: $doubleEnergyHoverColor;
  darkEnergyColor: $darkEnergyColor;
  darkEnergyHoverColor: $darkEnergyHoverColor;
  wizdomTanColor: $wizdomTanColor;
  panicColor: $panicColor;
  skillsGoodColor: $skillsGoodColor;
  skillsBadColor: $skillsBadColor;
  dimDarknessColor: $dimDarknessColor;
}
