@import 'uikit/styles/fonts';

.Container {
  width: 357px;
  top: 18px;
  right: 56px;
}

.Body {
  margin: 0;
}

.Reset {
  padding: 0;
  overflow: visible;
  box-shadow: unset;
  background: transparent;
}

.NotificationWrapper {
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.21);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;
  min-height: 80px;
  padding: 0;
  font-weight: 500;
  font-family: $familyTTNorms;
  color: #000000;
  font-size: 18px;
  background: #ffffff;
}

.InformationWrapper {
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: hidden;
  min-height: 150px;
  font-weight: 500;
  font-family: $familyTTNorms;
  color: #000000;
  font-size: 18px;
  background-color: #fade76;
}

.Header {
  display: flex;
  align-items: center;
  padding: 24px;

  &.Error {
    @keyframes flash {
      0% {
        background: #fade76;
      }
      100% {
        background: #ffcd50;
      }
    }

    animation: flash linear 2s infinite;
  }
}

.Emoji {
  font-size: 36px;
  margin-right: 24px;
}

.Message {
  padding: 24px;
  padding-right: 50px;
}

.Information {
  padding: 34px 48px 40px 40px;
}

.CloseButton {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  display: flex;
  width: 50%;
  padding: 16px;
  height: 100%;
  align-items: flex-start;
  opacity: 0.5;
  transition: opacity 0.5s;
  background: transparent;
  outline: 0;
  z-index: 1;

  &.ContentClickable {
    width: 48px;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.8;
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.Vincent {
  position: absolute;
  bottom: 18px;
  right: -49px;
}

@media only screen and (max-width: 425px) {
  .InformationWrapper {
    border-radius: 16px 0 16px 16px;
  }
  .Vincent {
    display: none;
  }
  .Container {
    width: auto;
    top: 10px;
    right: 10px;
    left: 10px;
  }
  .Information {
    padding: 30px;
  }
}
