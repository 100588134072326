/* Generated by ParaType (http://www.paratype.com)*/
/* Font Fact: Copyright ? ParaType, 2018. All rights reserved.*/


@font-face {
  font-family: 'FactWebItalic';
  src: url('Fact_Italic.eot');
  src: url('Fact_Italic.eot?#iefix') format('embedded-opentype'),
  url('Fact_Italic.woff2') format('woff2'),
  url('Fact_Italic.woff') format('woff'),
  url('Fact_Italic.ttf') format('truetype'),
  url('Fact_Italic.svg#FactWeb-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
