@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-ExtraLight.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-ExtraLightItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-LightItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}


@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: url('./TTNorms-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
