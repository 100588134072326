@keyframes spinInside {
  0% {
    transform: scale(1, 0);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 0);
  }
}
@keyframes spinMainshell {
  0% {
    transform: scale(1, -1);
    top: 0;
  }
  25% {
    transform: scale(1, 1);
    top: 50%;
  }
  75% {
    transform: scale(1, -1);
    top: 0;
  }
}
@keyframes spinCshell {
  0% {
    transform: scale(1, 0);
  }
  50% {
    transform: scale(1, 0);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0);
  }
}
@keyframes spinAll {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Inner {
  position: relative;
  width: 32px;
  height: 32px;
  animation: spinAll 7s infinite linear;
}

.Mainshell {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 50%;
  border-bottom-left-radius: 50% 100%;
  border-bottom-right-radius: 50% 100%;
  animation: spinMainshell 0.8s infinite step-start;
}

.Edge {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(1, 0.5);
  animation: spinInside 0.8s infinite linear;
}

.Cshell {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: spinCshell 0.8s infinite linear;
}

.Inside {
  position: absolute;
  width: 80%;
  height: 80%;
  left: 10%;
  top: 10%;
  border-radius: 50%;
  transform: scale(1, 0.5);
  animation: spinInside 0.8s infinite linear;
}

.ThemeDark {
  .Mainshell,
  .Edge,
  .Cshell {
    background-color: #3d3d3d;
  }

  .Inside {
    background-color: #ffffff;
  }
}

.ThemeLight {
  .Mainshell,
  .Edge,
  .Cshell {
    background-color: #ffffff;
  }

  .Inside {
    background-color: #3d3d3d;
  }
}
