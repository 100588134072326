/* Generated by ParaType (http://www.paratype.com)*/
/* Font Fact: Copyright ? ParaType, 2018. All rights reserved.*/


@font-face {
  font-family: 'FactWebSemiExpandedExtraBold';
  src: url('Fact_Semi Expanded Extra Bold.eot');
  src: url('Fact_Semi Expanded Extra Bold.eot?#iefix') format('embedded-opentype'),
  url('Fact_Semi Expanded Extra Bold.woff2') format('woff2'),
  url('Fact_Semi Expanded Extra Bold.woff') format('woff'),
  url('Fact_Semi Expanded Extra Bold.ttf') format('truetype'),
  url('Fact_Semi Expanded Extra Bold.svg#Fact-SemiExpandedWebExtraBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
