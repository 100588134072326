@import '../../styles/fonts';
@import 'uikit/styles/typography';

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.Header1 {
  font-family: $familyTTNorms;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

.ColorBlack {
  color: #000000;
}

.ColorGray {
  color: #3d3d3d;
}

.SizeLarge {
  font-size: $header1WideFontSizeL;
  font-weight: 800;
}

.SizeMedium {
  font-size: $header1WideFontSizeM;
  font-weight: 800;
}

.SizeSmall {
  font-size: $header1WideFontSizeS;
  font-weight: 400;
}

.P {
  font-family: $familyLato;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
}

@media (max-width: $headerMediumWindowWidth) {
  .SizeLarge {
    font-size: $header1MediumFontSizeL;
  }
}

@media (max-width: $headerSmallWindowWidth) {
  .SizeLarge {
    font-size: $header1SmallFontSizeL;
  }
}
