.Input {
  position: relative;
  display: inline-block;
  text-align: left;
  padding: 0;
  font-size: 18px;
}

.Control {
  outline: none;
  text-overflow: ellipsis;
  background: none;
  z-index: 3;
  border: none;
  font-weight: 500;
  caret-color: currentColor;
  width: 100%;
  -webkit-appearance: none;

  &[readonly] {
    cursor: default;
  }

  &::placeholder {
    font-weight: normal;
    opacity: 0.4;
  }

  &,
  &:disabled {
    color: currentColor;
  }
}

.Label {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  background: none;
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: italic;
  pointer-events: none;
  transition: left 0.16s ease, top 0.16s ease, font-size 0.16s ease;
}

.SizeMedium {
  .Control {
    font-size: 16px;
  }

  .Label {
    left: 0;
    top: 8px;
    line-height: 27px;
  }
}

.FashionFat {
  &.ThemeLight {
    background: #ffffff;

    &.Disabled {
      background: rgba(#ffffff, 0.08);

      .Label {
        color: #ffffff;
      }
    }

    &.Focused {
      background: #ffe99a;
    }

    .Label {
      color: #a1a19f;
    }

    &.Filled,
    &.Focused {
      .Label {
        color: rgba(#ffffff, 0.6);
      }
    }
  }

  &.ThemeDark {
    background: #fff5ce;

    &.Disabled {
      background: rgba(#000000, 0.05);

      .Label {
        color: #000000;
      }
    }

    &.Focused {
      background: #ffe99a;
    }

    .Label {
      color: #aca17a;
    }

    &.Filled,
    &.Focused {
      .Label {
        opacity: 0.5;
        color: #000000;
      }
    }
  }

  &.SizeMedium {
    border-radius: 3px;
    padding: 10px 12px;

    .Label {
      left: 8px;
      top: 9px;
    }

    &.Filled,
    &.Focused {
      .Label {
        font-size: 12px;
        top: -22px;
        left: 0;
      }
    }
  }
}

.FashionSlim {
  &.ThemeDark {
    .Control {
      border-bottom: 2px solid #2d30f3;
    }

    .Label {
      color: #2d30f3;
    }

    &.Error .Control {
      border-bottom: 2px solid #e04012;
    }

    &.Disabled {
      .Control {
        border-bottom: 2px solid rgba(#000000, 0.1);
      }
      .Label {
        color: currentColor;
      }
    }

    &.Focused {
      .Control {
        border-bottom: 2px solid #000000;
      }
    }

    &.Filled,
    &.Focused {
      .Label {
        opacity: 0.5;
        color: #000000;
      }
    }
  }

  &.ThemeLight {
    .Control {
      border-bottom: 2px solid #ffffff;
    }

    .Label {
      opacity: 0.8;
    }

    &.Error .Control {
      border-bottom: 2px solid #e04012;
    }

    &.Disabled {
      border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }

    &.Filled,
    &.Focused {
      .Label {
        opacity: 0.6;
      }
    }
  }

  .Label {
    left: 0;
  }

  &.SizeMedium {
    .Control {
      padding: 12px 0 4px 0;
    }

    &.Filled,
    &.Focused {
      .Label {
        font-size: 12px;
        top: -10px;
        left: 0;
      }
    }
  }

  &.ThemeAlemira {
    .Control {
      border-bottom: 2px solid #5021d7;
    }

    .Label {
      color: #5021d7;
      overflow: visible;
    }

    &.Error .Control {
      border-bottom: 2px solid #e04012;
    }

    &.Disabled {
      .Control {
        border-bottom: 2px solid rgba(#000000, 0.1);
      }
      .Label {
        color: currentColor;
      }
    }

    &.Focused {
      .Control {
        border-bottom: 2px solid #000000;
      }
    }

    &.Filled,
    &.Focused {
      .Label {
        opacity: 0.5;
        color: #000000;
      }
    }
  }
}

.Disabled {
  .Control {
    opacity: 0.4 !important;
  }

  .Label {
    opacity: 0.3 !important;
  }

  &.Filled,
  &.Focused {
    .Label {
      opacity: 0.5 !important;
    }
  }
}

.FullWidth {
  width: 100%;
}

.ThemeDark {
  color: #000000;
}

.ThemeLight {
  color: #ffffff;
}

.PasswordToggle {
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 8px;
  outline: none;
}

.ErrorMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #ffffff;
  position: absolute;
  width: 100%;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.26);
  background-color: #e04012;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.2px;
  z-index: 6;
  padding: 16px;
  box-sizing: border-box;

  &.LeftPosition {
    width: 232px;
    left: -240px;
    bottom: 0;
    border-radius: 8px 8px 0 8px;
  }

  a {
    color: #ffffff;
  }
}
