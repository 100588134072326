.Logo {
  width: 48px;
  height: 48px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.SecondLogo {
  height: 48px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left center;

  img {
    height: 100%;
    width: auto;
  }
}

.ThemeDark {
  /* defined in appearance.yml */
}

.ThemeLight {
  /* defined in appearance.yml */
}

@media only screen and (max-width: 768px) {
  .Logo {
    width: 32px;
    height: 32px;
  }
  .SecondLogo {
    height: 32px;
  }
}

@media (max-width: 375px) {
  .SecondLogo {
    display: none;
  }
}
