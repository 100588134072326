@import 'uikit/styles/fonts';

.Modal {
  display: flex;
}

.ModalContent {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  font-family: $familyTTNorms;
}

.PreHeader {
  font-family: $familyTTNorms;
  color: #ffffff;
  line-height: 2em;
  font-weight: 600;
  width: 100%;
}

@media (min-width: 400px) {
  .Modal {
    max-width: 360px;
  }
}

@media (min-width: 768px) {
  .ModalContent {
    flex-direction: row;
  }
}
