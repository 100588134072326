@import 'uikit/styles/fonts';
@import 'uikit/styles/dialogColors';
@import 'uikit/styles/dialogFonts';

.Modal {
  background: none;
  font-family: $familyFact;
}

.SignInWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form {
  position: relative;
  width: 360px;
  padding: 48px 48px 104px;
  box-sizing: border-box;
  background: $matterColor;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.127632);
}

.EmailFormField {
  margin-top: 38px;
}

.FormField {
  margin-top: 18px;
}

.AgreementCheckboxWrapper {
  margin-top: 44px;
  display: flex;
}

.SubmitWrapper {
  position: absolute;
  width: 100%;
  height: 56px;
  bottom: 0;
  left: 0;
  font-family: $familyLato;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
}

.SubmitButton {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: $energyColor;
  border-radius: 0 0 8px 8px;
  border-width: 0;
  color: $matterColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0;

  &:hover {
    background: $energyHoverColor;
  }
}

.Link {
  color: $energyColor;

  &:hover {
    color: $energyHoverColor;
  }
}

.Message {
  width: 100%;
  height: 100%;
  border-radius: 0 0 8px 8px;
  background: $wizdomTanColor;
  color: $antiMatterColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.Warning {
    color: $panicColor;
  }
}

.OrText {
  margin-top: 13px;
  margin-bottom: 18px;
  font-family: Fact;
  color: $matterColor;
  font-size: 18px;
  line-height: 120%;
}

.GoogleSignIn {
  position: relative;
  cursor: pointer;
  width: 360px;
  height: 56px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.GoogleLogo {
  position: absolute;
  width: 34px;
  height: 34px;
  left: 14px;
  background: url(Goo.svg) no-repeat center;
}

.FacebookSignIn {
  position: relative;
  margin-top: 18px;
  cursor: pointer;
  width: 360px;
  height: 56px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.FacebookLogo {
  position: absolute;
  width: 34px;
  height: 34px;
  left: 14px;
  background: url(Fac.svg) no-repeat center;
}
