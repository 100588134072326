.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  text-align: center;
  outline: none;
  border-radius: 48px;
  cursor: pointer;
  transition: background-color 0.1s ease-out,
  border-color 0.2s ease-out,
  transform 0.1s ease-out;
  text-decoration: none;
  box-sizing: border-box;
  padding: 0 1.3em;
  min-width: 5em;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.99);
    transition: none;
  }
}

.Label {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
}

.Button:disabled,
.Button:disabled:active,
.Button:disabled:focus,
.Button:disabled:hover {
  cursor: initial;
  transform: none;
  transition: none;
  box-shadow: none;
}

.SizeLarge {
  height: 64px;
  font-size: 24px;
}

.SizeMedium {
  height: 48px;
  font-size: 18px;
}

.SizeSmall {
  height: 36px;
  font-size: 15px;
}

.ThemeClean {
  height: auto;
  background: none;
  padding: 0;
  border-radius: 0;
  font-weight: normal;
  text-transform: none;
  color: #000000;

  &:hover {
    transform: none;
  }
}

.ThemeBlack {
  color: #ffffff;
  background-color: #000000;
  box-shadow: 0 5px 32px rgba(0,0,0,0.16);

  &:hover {
    background-color: #2f203a;
  }
  &:active {
    background-color: #000000;
  }
}

.ThemeRed {
  color: #ffffff;
  background-color: #e04012;
  box-shadow: 0 5px 32px rgba(185,0,14,0.31);

  &:hover {
    background-color: #f34513;
  }
  &:active {
    background-color: #e04012;
  }
}

.ThemeGreen {
  color: #ffffff;
  background-color: #38ce6f;
  box-shadow: 0 5px 32px rgba(44,201,12,0.36);

  &:hover {
    background-color: #38d471;
  }
  &:active {
    background-color: #38ce6f;
  }
}

.ThemeBlankRed {
  color: #e04012;
  background-color: transparent;
  border: 3px solid #e04012;
}

.ThemeUnimportantDark {
  color: #2d30f3;
  background-color: transparent;
  border: solid 2px #2d30f3;

  &:hover {
    color: #f31e55;
    border: solid 2px #f31e55;
    transition: none;
    transform: none;
  }

  &:active {
    transform: none;
    color: #2d30f3;
    border: solid 2px #2d30f3;
  }
}

.ThemeUnimportantLight {
  color: #ffffff;
  background-color: transparent;
  border: solid 2px #ffffff;

  &:hover {
    color: #f74875;
    border: solid 2px #f74875;
    transition: none;
    transform: none;
  }

  &:active {
    transform: none;
    color: #ffffff;
    border: solid 2px #ffffff;
  }
}

.ThemeYellow {
  color: #333333;
  background-color: #ffe24a;
  box-shadow: 0 5px 32px rgba(255,205,8,0.35);

  &:hover {
    background-color: #ffd353;
  }
  &:active {
    background-color: #ffe24a;
  }
}

.ThemeBorderedWhite {
  color: #ffffff;
  background: transparent !important;
  border: 3px solid #ffffff;
}

.ThemeWhite {
  background-color: #ffffff;
  color: inherit;
}

.DisabledThemeLight {
  &,
  &:disabled,
  &:disabled:active,
  &:disabled:focus,
  &:disabled:hover {
    color: rgba(#333333, 0.16);
    background: rgba(#000000, 0.06);
    border-color: transparent;
  }
}

.DisabledThemeDark {
  &,
  &:disabled,
  &:disabled:active,
  &:disabled:focus,
  &:disabled:hover {
    color: rgba(#ffffff, 0.2);
    background: rgba(#ffffff, 0.12);
    border-color: transparent;
  }
}

.TrimmedLabel {
  padding: 0 !important;
}

.FullWidth {
  width: 100%;
}
