@import 'uikit/styles/fonts';
@import 'uikit/styles/dialogColors';

.Body {
  margin: 0;
}

.Reset {
  padding: 0;
  overflow: visible;
  box-shadow: unset;
  background: transparent;
}

.NotificationWrapper {
  background: $energyColor;
  box-shadow: 0 20px 40px rgba(57, 53, 232, 0.24);
  border-radius: 16px 0 16px 16px;
  font-size: 18px;
  font-family: $familyTTNorms;
  line-height: 21px;
  color: $matterColor;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  padding: 16px;
}

.Emoji {
  font-size: 36px;
  line-height: 42px;
  margin-right: 24px;
}
