@import 'uikit/styles/fonts';
@import 'uikit/styles/zIndex';


.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: $zIndexModal;
  height: 100%;
  overflow-y: scroll;
}

.Modal {
  padding: 0 10px;
  outline: none;
  min-height: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
}

.Header {
  display: flex;
  align-items: center;
}

.CloseButton {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0;
  display: flex;
  border: none;
  background-color: transparent;
  outline: none;
  justify-content: center;
  align-items: center;
  z-index: 11;

  &:hover {
    opacity: 0.6;
  }
}

.InnerWrapper {
  background-color: #ffffff;
  position: relative;
  border-radius: 10px;
  display: flex;
}

@media (min-width: 575px) {
  .Modal {
    margin: 1rem auto;
    max-width: 360px;
  }
}

@media (min-width: 768px) {
  .Modal {
    margin: 1.75rem auto;
    min-height: calc(100% - (1.75rem * 2));
    justify-content: center;
    align-items: center;
  }
}
