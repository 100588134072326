.Link {
  text-decoration: none;
  transition: color 0.1s ease;
  cursor: pointer;

  &:hover {
    transition: none;
  }
}

.ThemeClean {
  color: initial;
  transition: none;

  &:hover {
    color: initial;
  }
}

.ThemeDark {
  /* defined in appearance.yml */
}

.ThemeLight {
  /* defined in appearance.yml */
}
