.SelectionControlGroup {
  position: relative;
}

.Item {
  & + & {
    margin: 8px 0 0 0;
  }
}

.Inline {
  .Item + .Item {
    margin: 0 0 0 16px;
  }
}

.KindButton {
  .Item:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -1px;
  }

  .Item + .Item {
    margin: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ErrorMessage {
  display: flex;
  color: #ffffff;
  position: absolute;
  width: 232px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.26);
  background-color: #e04012;
  font-size: 15px;
  letter-spacing: 0.2px;
  z-index: 6;
  padding: 16px;
  box-sizing: border-box;

  &.LeftPosition {
    bottom: 0;
    left: -240px;
    border-radius: 8px 8px 0 8px;
  }

  a {
    color: #ffffff;
  }
}
