
@font-face {
  font-family: 'FactWebSemiExpanded';
  src: url('Fact_Semi Expanded Medium.eot');
  src: url('Fact_Semi Expanded Medium.eot?#iefix') format('embedded-opentype'),
  url('Fact_Semi Expanded Medium.woff2') format('woff2'),
  url('Fact_Semi Expanded Medium.woff') format('woff'),
  url('Fact_Semi Expanded Medium.ttf') format('truetype'),
  url('Fact_Semi Expanded Medium.svg#Fact-SemiExpandedWebMedium') format('svg');
  font-weight: normal;
  font-style: normal;
}
