.Form {
  padding: 46px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.13);
  z-index: 10;
}

.Header {
  margin-bottom: 48px;
}

.SuccessMessage {
  color: #38d471;
}

.SpinnerContainer {
  height: 100px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.FullHeightSpinner {
  height: 100%;
}

.ErrorMessage {
  padding: 16px 0;
  height: 32px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  span {
    font-size: 16px;
    color: #e04012;
  }
}

.AgreementCheckboxWrapper {
  margin: 36px 0;
  display: flex;
}

.Links {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  .Link + .Link {
    margin-left: 5px;
  }
}

.Link {
  font-size: 13px;
  color: #2d30f3;
}

.ButtonInError:hover {
  color: #fade76;
  border: solid 2px #fade76;
}

.ButtonMiddle {
  margin: 18px 0;
}

.ButtonBottom {
  margin-top: 18px;
  margin-bottom: 8px;
}

@media (min-width: 400px) {
  .Form {
    width: 360px;
  }
}
