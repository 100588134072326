@font-face {
  font-family: 'Lato';
  src: url('./Lato-Italic.woff') format('woff');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('./Lato-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('./Lato-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('./Lato-SemiboldItalic.woff') format('woff');
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
