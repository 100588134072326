.SelectionControl {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  white-space: nowrap;
  line-height: 22px;

  &:active {
    .Control:not([disabled]) + .Mark {
      transform: scale(1.1);
      transition: transform 0.01s ease;
    }
  }
  &.Error .Mark {
    border: 2px solid #e04012;
  }
}

.Control {
  position: absolute;
  z-index: 3;
  left: -9999px;
  margin: 0;
  padding: 0;
  border: 0;

  & + .Mark {
    cursor: pointer;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    transition: all 0.2s ease-out;
    border: 2px solid #000000;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 0;
  }

  &:focus:not([disabled]) + .Mark {
    box-shadow: 0 0 8px 0 #fade76;
  }
}

.Label {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.Inline {
  display: inline-flex;
}

.ControlTypeCheckbox {
  padding-left: 31px;

  .Control {
    & + .Mark {
      border-radius: 2px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPlJlY3RhbmdsZS00PC90aXRsZT48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjA2MiA2LjUyNHYuMDAyTDMuNDc0IDcuOTRoLjAwMmwuMDA4LjAwN0w0LjkgNi41MzNsLS4wMS0uMDEgNC41MjUtNC41MjJMOCAuNTg3IDMuNDc3IDUuMTEgMS45MyAzLjU2My41MTQgNC45NzdsMS41NDcgMS41NDd6IiBpZD0iQ0IiIGZpbGw9IiMwMDAiLz48L2c+PC9zdmc+Cg==);
    }

    &:checked + .Mark {
      background-size: 80%;
    }
  }

  &.ThemeLight.Disabled {
    .Control {
      & + .Mark {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPlJlY3RhbmdsZS00PC90aXRsZT48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjA2MiA2LjUyNHYuMDAyTDMuNDc0IDcuOTRoLjAwMmwuMDA4LjAwN0w0LjkgNi41MzNsLS4wMS0uMDEgNC41MjUtNC41MjJMOCAuNTg3IDMuNDc3IDUuMTEgMS45MyAzLjU2My41MTQgNC45NzdsMS41NDcgMS41NDd6IiBpZD0iQ0IiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+Cg==);
      }
    }
  }
}

.ControlTypeRadio {
  padding-left: 33px;

  .Control {
    & + .Mark {
      border-radius: 50%;
      height: 20px;
      width: 20px;
    }

    &:checked + .Mark {
      background-size: 12px;
    }
  }

  .Control {
    & + .Mark {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgNiA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkNvcmUtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOTMuMDAwMDAwLCAtMzY5LjAwMDAwMCkiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbC0yIiBjeD0iMTE5NiIgY3k9IjM3MiIgcj0iMyI+PC9jaXJjbGU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
    }
  }

  &.ThemeLight.Disabled {
    .Control {
      & + .Mark {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgNiA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkNvcmUtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOTMuMDAwMDAwLCAtMzY5LjAwMDAwMCkiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbC0yIiBjeD0iMTE5NiIgY3k9IjM3MiIgcj0iMyI+PC9jaXJjbGU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
      }
    }
  }
}

.Disabled {
  opacity: 0.4;

  &,
  .Control + .Mark {
    cursor: default;
  }
}

.ThemeDark {
  color: #2d30f3;
}

.ThemeAlemira {
  color: #000000;
}

.ThemeLight {
  color: #ffffff;

  .Control {
    & + .Mark {
      border-color: #ffffff;
      background-color: #ffffff;
    }
  }

  &.Disabled {
    .Control + .Mark {
      background-color: #000000;
    }
  }
}

.KindButton {
  height: 32px;
  border-radius: 4px;
  border: 2px #2d30f3 solid;
  padding: 0 10px;

  .Label {
    font-weight: 500;
  }

  &.Checked {
    z-index: 1;
    background: #000000;
    border-color: #000000;

    .Label {
      color: #ffffff;
    }
  }

  .Control {
    & + .Mark {
      display: none;
    }
  }
}
